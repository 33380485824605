import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Pricing from '../../components/Pricing/Block02'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'
import { Script } from "gatsby"
import FloatingLeadForm from '../../components/LeadForm/LeadForm'
import Calendly from '../../components/Calendly/Calendly'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Innovate Faster with Feature Flags - Toggly Pricing'
      description='Toggly makes it easier for software developers to build better software faster by making it easy to test, release, and measure features without the risk of downtime or other production impacts.'
      keywords={['feature flags, software development']}
      author='opsAI LLC'
      category='Software Development'
      thumbnail='https://toggly.io/cover.png'
      siteUrl='https://toggly.io'
      locale='en_US'
      />

      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='2' />
      <Calendly />
      <Divider space='5' />
      {/* <Companies content={content['companies']} />
      <Divider space='5' /> */}
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />

      <FloatingLeadForm />

      {/* <Script id="hs-script-loader" async defer src="//js.hs-scripts.com/7346852.js" /> */}

    </Layout>
  )
}

export const query = graphql`
  query homepageSaasV2BlockContent {
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
